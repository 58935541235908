import { alphabeticalSort } from './index'

let checkNested = (element, findFunc) => {
  if (!element.forms) return
  switch (element.type) {
    case 'group':
    case 'repeatForBool':
    case 'repeatForNumber':
      return findField(element.forms, findFunc)
    default:
      throw new Error(`Unknown field type ${element.type}`)
  }
}
/**
 * Find a particular element from kind.form
 * element - kind.form object, or kind.form.forms array
 * findFunc - function that returns true if element fits the search
 * (recursive)
 */
export function findField (element, findFunc) {
  if (!element) return
  if (!Array.isArray(element)) {
    let findElem = checkNested(element, findFunc)
    if (findElem) return findElem
  } else {
    for (let child of element) {
      if (findFunc(child)) {
        return child
      }
      let findElem = checkNested(child, findFunc)
      if (findElem) return findElem
    }
  }
}

export function findFieldByPublicId (details, publicId) {
  return findField(details,
    el => el.publicId && el.publicId === publicId)
}

let kindOptions = (kind, publicId) => {
  let field = findFieldByPublicId(kind && kind.form, publicId)
  if (field && field.options) {
    return field.options
  } else {
    return []
  }
}

export function getKindOptions (kindObj, publicId, { sort = false } = {}) {
  let options
  if (Array.isArray(kindObj)) {
    // if multiple kinds
    options = kindObj.reduce((allOptions, kind) => {
      let newOptions = kindOptions(kind, publicId)
      return allOptions.concat(newOptions)
    }, [])
  } else {
    // if single kind
    options = kindOptions(kindObj, publicId)
  }
  // remove any duplicates with Set
  options = Array.from(new Set(options))
  if (sort) {
    options = alphabeticalSort(options)
  }
  return options
}
