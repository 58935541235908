import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { changeStateProp, showSpinner,mergeStateProp } from '../../../actions'
import LotsList from './LotsList'

const mapStateToProps = (state) => {
  return {
    kinds: state.data.kinds,
    sortChoice: state.main.lotSort.choice,
    sortDirection: state.main.lotSort.direction,
    userId: state.user.data._id,
    filters: state.user.filters,
    locationStates: state.data.locationStates
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ changeStateProp, showSpinner,mergeStateProp }, dispatch)
}

const LotsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LotsList)

export default LotsListContainer
